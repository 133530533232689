import React, { useState } from "react";
import { graphql, Link, PageProps } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCheckCircle,
  faCircle,
  faCircleExclamation,
  faEnvelope,
  faGlobe,
  faLink,
  faLocationDot,
  faPaperclip,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  cellphoneRegex,
  getAbsolutePageUrl,
  getApiUrl,
  getPageUrl,
  getSafeExternalUrl,
  phoneRegex,
  sendFormJson,
} from "src/utils";
import { DiscussionEmbed } from "disqus-react";
import siteConfig from "src/site-config";
import SEO from "components/seo";
import settingsJson from "settings/contact-page.json";
import * as yup from "yup";
import { Button, Form, Spinner } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";

/* #region Functions */
function getContactUrl(value: string, contactType: string) {
  switch (contactType) {
    default:
    case "url":
      return getSafeExternalUrl(value);

    case "email":
      return `mailto:${value}`;

    case "whatsapp":
      return `https://wa.me/55${value.replace(/\D+/g, "")}`;

    case "phone":
      return `tel:${value.replace(/\D+/g, "")}`;

    case "social":
      return getSafeExternalUrl(value);
  }
}

function getContactIcon(icon: string) {
  switch (icon) {
    default:
      return faCircle;
    case "envelope":
      return faEnvelope;
    case "phone":
      return faPhone;
    case "cellphone":
      return faPhone;
    case "link":
      return faLink;
    case "globe":
      return faGlobe;
    case "externalLink":
      return faArrowUpRightFromSquare;
    case "paperclip":
      return faPaperclip;
    case "location":
      return faLocationDot;
    case "whatsapp":
      return faWhatsapp;
    case "facebook":
      return faFacebook;
    case "twitter":
      return faTwitter;
    case "instagram":
      return faInstagram;
    case "linkedin":
      return faLinkedin;
    case "youtube":
      return faYoutube;
  }
}

function prepareFormValues(values: FormValues) {
  return {
    ...values,
    "cellphone-number": values.cellphone.replace(/[^0-9.]/, ""),
    "phone-number": values.phone.replace(/[^0-9.]/, ""),
  };
}
/* #endregion */

/* #region Types */
interface FormValues {
  name: string;
  email: string;
  cellphone: string;
  phone: string;
  company: string;
  companyUrl: string;
  newsletter: boolean;
}

interface ContactPageSettings {
  enableComments: boolean;
  contacts:
    | []
    | {
        title?: string | null;
        type: string;
        value: string;
        icon?: string | null;
        description?: string | null;
      }[];
}

interface ContactPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
        siteUrl: string;
      };
    };
  };
}
/* #endregion */

/* #region Variables */
const formSchema = yup.object().shape({
  jobId: yup.string().nullable(),
  name: yup
    .string()
    .required("O nome é obrigatório")
    .min(3, "Nome muito curto"),
  email: yup
    .string()
    .required("O email é obrigatório")
    .email("Digite um email válido"),
  cellphone: yup
    .string()
    .required("O celular/whatsapp é obrigatório")
    .matches(cellphoneRegex, "Digite um celular válido"),
  phone: yup
    .string()
    .notRequired()
    .matches(phoneRegex, "Digite um telefone válido"),
  company: yup.string().notRequired(),
  companyUrl: yup.string().notRequired(),
  newsletter: yup.bool().required(),
});

const initialValues: FormValues = {
  name: "",
  email: "",
  cellphone: "",
  phone: "",
  company: "",
  companyUrl: "",
  newsletter: false,
};
/* #endregion */

const ContactPage = ({ data, location }: ContactPageProps) => {
  const { siteName, siteUrl } = data.site.siteMetadata;
  const pageSettings: ContactPageSettings = settingsJson;
  const disqusConfig = {
    shortname: siteConfig.disqusConfig.shortName,
    config: {
      // Use canonical slug so comments are never lost
      identifier: `contact-page`,
    },
  };
  const [sendSucceed, setSendSuccess] = useState<boolean | null>(null);
  const [formTouched, setFormTouched] = useState(false);

  const formSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const preparedValues = prepareFormValues(values);

    sendFormJson(getApiUrl("/api/forms/contact"), preparedValues, true)
      .then((r) => {
        formikHelpers.resetForm();
        setFormTouched(false);
        setSendSuccess(true);
      })
      .catch(() => {
        setSendSuccess(false);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const formChanged = () => {
    if (sendSucceed) {
      setSendSuccess(null);
    }
  };

  const formBlurred = () => {
    if (!formTouched) {
      setFormTouched(true);
    }
  };

  return (
    <React.Fragment>
      <SEO
        title="Contato"
        canonicalUrl={getAbsolutePageUrl(location.pathname)}
      />

      <h1 className="card-title mb-3">Contato</h1>
      <h6 className="text-muted card-subtitle mb-2">Fale com a {siteName}</h6>
      <p>
        Deixe sua sugestão nos comentários ou entre em contato conosco por um
        dos nossos canais de atendimento.
      </p>
      <p>
        Seu comentário pode passar por moderação e ser avaliado pela nossa
        equipe antes de aparecer aqui.
      </p>

      <div className="py-3">
        <h3>Canais de atendimento:</h3>

        <ul style={{ listStyleType: "none" }}>
          {pageSettings.contacts?.map((c, i) => {
            return (
              <li key={i} className="py-2">
                {c.title && <h5>{c.title}</h5>}
                <a href={getContactUrl(c.value, c.type)} className="fs-5">
                  {c.icon && (
                    <FontAwesomeIcon
                      icon={getContactIcon(c.icon)}
                      className="me-2"
                    />
                  )}
                  {c.value}
                </a>
                <br />
                {c.description && <small>{c.description}</small>}
              </li>
            );
          })}
        </ul>
      </div>

      <div id="publique-sua-vaga" className="pb-3">
        <h3>Publique sua vaga:</h3>
        <p>
          <Link to="#formulario">Preencha o formulário</Link> abaixo ou entre em
          contato por um de nossos canais de atendimento para mais informações
          de como publicar sua vaga! Estamos prontos para atendê-lo &#128512;.
        </p>
      </div>

      <div className="pb-3">
        <h3>Faça uma denúncia:</h3>
        <p>
          Para denúncias, envie-nos um email contendo as seguintes informações:
        </p>
        <ul>
          <li>Motivo da denúncia</li>
          <li>Link da vaga</li>
          <li>Descrição / Informações</li>
        </ul>
      </div>

      <div id="formulario" className="pb-3">
        <h3>Formulário de contato:</h3>
        <p>
          Após preencher este formulário, entraremos em contato para continuar o
          procedimento, por isso, <b>é muito importante</b> preencher os dados
          de contato corretamente.
        </p>

        <Formik
          validationSchema={formSchema}
          onSubmit={formSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              onChange={formChanged}
              onBlur={formBlurred}
              className="text-start d-grid gap-3"
            >
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Informações Pessoais</p>
                </div>
                <div className="card-body">
                  <Form.Group className="mb-3" controlId="formikControl0">
                    <Form.Label>Nome completo:</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      placeholder="Ex: João da Silva"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formikControl1">
                    <Form.Label>Email para contato:</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                      placeholder="Ex: joaodasilva@gmail.com"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formikControl2">
                        <Form.Label>Celular / Whatsapp:</Form.Label>
                        <Form.Control
                          disabled={isSubmitting}
                          className="shadow-sm"
                          type="tel"
                          name="cellphone"
                          value={values.cellphone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.cellphone && !errors.cellphone}
                          isInvalid={touched.cellphone && !!errors.cellphone}
                          placeholder="Ex: (99) 9 9999-9999"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cellphone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formikControl3">
                        <Form.Label>Telefone:</Form.Label>
                        <Form.Control
                          disabled={isSubmitting}
                          className="shadow-sm"
                          type="tel"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={Boolean(
                            touched.phone && !errors.phone && values.phone
                          )}
                          isInvalid={touched.phone && !!errors.phone}
                          placeholder="Ex: (11) 1111-1111"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Informações Empresariais</p>
                </div>
                <div className="card-body">
                  <Form.Group className="mb-3" controlId="formikControl4">
                    <Form.Label>Nome da empresa (opcional):</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="text"
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.company && !errors.company && !!values.company
                      }
                      isInvalid={touched.company && !!errors.company}
                      placeholder="Ex: Clean Home Ltda."
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.company}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formikControl5">
                    <Form.Label>Site da empresa (opcional):</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="text"
                      name="companyUrl"
                      value={values.companyUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.companyUrl &&
                        !errors.companyUrl &&
                        !!values.companyUrl
                      }
                      isInvalid={touched.companyUrl && !!errors.companyUrl}
                      placeholder="Site, facebook, instagram ou etc"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.companyUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <Form.Group controlId={"formikControl6"}>
                    <Form.Check
                      disabled={isSubmitting}
                      type="switch"
                      name="newsletter"
                      label={
                        <div className="text-break">
                          {" "}
                          Marque esta opção para receber gratuitamente o resumo
                          das vagas publicadas diariamente na {siteName}{" "}
                          <small>
                            {"("}Certifique-se que o endereço de e-mail
                            fornecido acima esteja correto. Basta marcar somente
                            uma vez que você estará inscrito.{")"}
                          </small>
                        </div>
                      }
                      checked={values.newsletter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </div>
              </div>
              {isSubmitting ? (
                <div className="text-center text-white bg-info border rounded border-0 p-3 p-lg-4">
                  <Spinner animation="grow" size="sm" role="status" /> Enviando
                  informações...
                </div>
              ) : sendSucceed === true ? (
                <div className="text-center text-white bg-success border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="fs-5 me-2"
                    />
                    Recebemos a sua solicitação!
                    <br />
                    Agora é só aguardar uma resposta &#128521;.
                  </p>
                </div>
              ) : sendSucceed === false ? (
                <div className="text-center text-white bg-danger border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="fs-5 me-2"
                    />
                    Erro ao enviar solicitação. Por favor, tente novamente ou
                    contate-nos.
                  </p>
                </div>
              ) : null}
              {!isValid && formTouched && (
                <div className="text-center text-white bg-danger border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="fs-5 me-2"
                    />
                    Um ou mais campos possuem um erro.
                  </p>
                </div>
              )}
              <div className="text-center">
                <Button
                  variant="primary"
                  className="px-5"
                  size="lg"
                  type="submit"
                >
                  Enviar solicitação
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {siteConfig.disqusConfig.enable && pageSettings.enableComments && (
        <div className="pt-5">
          <DiscussionEmbed {...disqusConfig} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
  }
`;
